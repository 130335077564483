import {
    DashboardOutlined,
    TeamOutlined,
    ClockCircleOutlined,
    CalendarOutlined,
    RiseOutlined,
    HourglassOutlined,
    AppstoreOutlined,
    SwapRightOutlined,
    SwapOutlined,
    ApartmentOutlined,
    ClusterOutlined,
    CloudOutlined,
    CloudSyncOutlined,
    SwapLeftOutlined,
    VerticalAlignBottomOutlined,
    MinusOutlined,
    PlusOutlined,
    LineChartOutlined,
    ReadOutlined,
    ProfileOutlined,
    StarOutlined,
    ExclamationCircleOutlined,
    BranchesOutlined,
    BankOutlined,
    ScheduleOutlined,
    RocketOutlined,
    ContactsOutlined,
    SendOutlined,
    AlertOutlined,
    EnvironmentOutlined,
    DeploymentUnitOutlined,
    HomeOutlined,
    SearchOutlined,
    MergeCellsOutlined,
    GlobalOutlined,
    ToolOutlined,
    ColumnWidthOutlined,
    DollarCircleOutlined,
    ReloadOutlined,
    PieChartOutlined,
    BarChartOutlined,
    AimOutlined,
    LayoutOutlined,
    ContainerOutlined,
    FileSyncOutlined,
    FundOutlined,
    FundViewOutlined,
    MailOutlined,
    PictureOutlined,
    BlockOutlined,
    MenuOutlined,
    FormOutlined,
    ShopOutlined,
    ExportOutlined,
    MonitorOutlined,
    InboxOutlined,
    AuditOutlined,
    GiftOutlined,
    FolderOutlined,
    FieldNumberOutlined,
    CarryOutOutlined
} from '@ant-design/icons';

import {APP_PREFIX_PATH, THEME_CONFIG} from 'configs/AppConfig'
import {isMobile} from "react-device-detect"
import React from "react";


import {CakeOutlined} from '@mui/icons-material';


const ColorIcon = (Icon, color) => {
    if(THEME_CONFIG.sideNavTheme==="SIDE_NAV_DARK"){
        color="#FFF"
    }
    return () => <Icon style={{color: color}}/>
}

const dashboardColor = "#8E44AD"
const webColor = "#1ABC9C"
const CRMColor = "#2980B9"
const formColor = "#F39C12"
const communauteColor = "#F1C40F"
const financeColor = "#BA4A00"
const serviceColor = "#138D75"
const comptaColor = "#E74C3C"


const peNavTree = [{
    key: 'pe',
    path: `${APP_PREFIX_PATH}/parcours-evenement/list`,
    title: 'Parcours d\'évenement',
    icon: ColorIcon(CarryOutOutlined, communauteColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: [],
    forfaits: ["CRM", "COM", "FINANCE"],
    dev:true
}]


const dashBoardNavTree = [{
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    title: 'Tableaux de bord',
    icon: ColorIcon(DashboardOutlined, dashboardColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["dashboards"],
    forfaits: [],
    submenu: [
        {
            key: 'dashboard-default',
            path: `${APP_PREFIX_PATH}/dashboards/default`,
            title: "Vue d'ensemble",
            icon: ColorIcon(DashboardOutlined, dashboardColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["dashboards"],
        },
        {
            key: 'dashboard-previz',
            path: `${APP_PREFIX_PATH}/dashboards/previz`,
            title: 'sidenav.dashboard.previz',
            icon: ColorIcon(FundViewOutlined, dashboardColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["dashboards"],
        },
    ],
}]


const webNavTree = [{
    key: 'web',
    path: `${APP_PREFIX_PATH}/web/config`,
    title: 'sidenav.web',
    icon: ColorIcon(GlobalOutlined, webColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["web"],
    forfaits: [],
    submenu: [
        {
            key: 'web-config',
            path: `${APP_PREFIX_PATH}/web/config`,
            title: 'sidenav.web.config',
            icon: ColorIcon(ToolOutlined, webColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["web"],
        },
        {
            key: 'web-don',
            path: `${APP_PREFIX_PATH}/web/don`,
            title: 'sidenav.web.don',
            icon: ColorIcon(DollarCircleOutlined, webColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["web"],
        },
        {
            key: 'web-pages',
            path: `${APP_PREFIX_PATH}/web/pages`,
            title: 'sidenav.web.pages',
            icon: ColorIcon(BlockOutlined, webColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["COM", "FINANCE", "ESSENTIEL"],
            permission: ["web"],
        },
        {
            key: 'web-menu',
            path: `${APP_PREFIX_PATH}/web/menu`,
            title: 'sidenav.web.menu',
            icon: ColorIcon(MenuOutlined, webColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["COM", "FINANCE", "ESSENTIEL"],
            permission: ["web"],
        },

        {
            key: 'web-fete',
            path: `${APP_PREFIX_PATH}/form/fete`,
            title: 'sidenav.form.fete',
            icon: ColorIcon(GiftOutlined, webColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM", "FINANCE", "ESSENTIEL"],
            permission: ["form"],
        },
    ]
}]

const formNavTree = [{
    key: 'form',
    path: `${APP_PREFIX_PATH}/form/simple`,
    title: 'sidenav.form',
    icon: ColorIcon(FormOutlined, formColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["form"],
    forfaits: ["CRM", "COM", "FINANCE"],
    submenu: [
        {
            key: 'form-simple',
            path: `${APP_PREFIX_PATH}/form/simple`,
            title: 'sidenav.form.simple',
            icon: ColorIcon(FormOutlined, formColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["form"],
        },
        {
            key: 'form-complex',
            path: `${APP_PREFIX_PATH}/form/complex`,
            title: 'sidenav.form.complex',
            icon: ColorIcon(ShopOutlined, formColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM", "FINANCE"],
            permission: ["form"],
        },
        {
            key: 'form-calendar',
            path: `${APP_PREFIX_PATH}/form/calendar`,
            title: 'sidenav.form.calendar',
            icon: ColorIcon(CalendarOutlined, formColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["form"],
            //dev:true,
        },
    ]
}]

const crmNavTree = [{
    key: 'crm',
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    title: 'sidenav.manage',
    icon: ColorIcon(TeamOutlined, CRMColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["crm"],
    forfaits: [],
    submenu: [
        {
            key: 'contact-list',
            path: `${APP_PREFIX_PATH}/contact/catalogue`,
            title: 'sidenav.contact.list',
            icon: ColorIcon(ContactsOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["crm"],
        },
        {
            key: 'famille-list',
            path: `${APP_PREFIX_PATH}/contact/famille`,
            title: 'sidenav.contact.famille',
            icon: ColorIcon(HomeOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["crm"],
        },
        {
            key: 'maps-list',
            path: `${APP_PREFIX_PATH}/contact/maps`,
            title: 'sidenav.contact.maps',
            icon: ColorIcon(EnvironmentOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["crm"],
        },
        /*
                {
                    key: 'rdv-list',
                    path: `${APP_PREFIX_PATH}/rdv/list`,
                    title: 'sidenav.rdv.list',
                    icon: ColorIcon(ScheduleOutlined, CRMColor),
                    breadcrumb: false,
                    submenu: [],
                    forfaits: ["CRM", "COM"],
                    permission: ["crm"],
                },
        */

        {
            key: 'agenda-list',
            path: `${APP_PREFIX_PATH}/agenda`,
            title: 'sidenav.agenda.list',
            icon: ColorIcon(CalendarOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["crm"],
        },
       {
           key: 'rappel-list',
           path: `${APP_PREFIX_PATH}/rappel`,
           title: 'sidenav.rappel.list',
           icon: ColorIcon(AlertOutlined, CRMColor),
           breadcrumb: false,
           submenu: [],
           forfaits: ["CRM", "COM"],
           permission: ["crm"],
       },
        {
            key: 'messages-list',
            path: `${APP_PREFIX_PATH}/messages/campagnes`,
            title: 'sidenav.messages.list',
            icon: ColorIcon(SendOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["crm"],
        },
        {
            key: 'template-email',
            path: `${APP_PREFIX_PATH}/templates/email`,
            title: 'sidenav.template.email',
            icon: ColorIcon(MailOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["crm"],
        },
        {
            key: 'contact-search',
            path: `${APP_PREFIX_PATH}/contact/search`,
            title: 'sidenav.contact.search',
            icon: ColorIcon(SearchOutlined, CRMColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["CRM", "COM"],
            permission: ["crm"],
        },
    ]
}]

const communauteNavTree = [{
    key: 'fideles',
    path: `${APP_PREFIX_PATH}/fideles/list`,
    title: 'sidenav.communaute',
    icon: ColorIcon(HomeOutlined, communauteColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["communaute"],
    forfaits: [],
    submenu: [
        {
            key: 'fideles-list',
            path: `${APP_PREFIX_PATH}/fideles/list`,
            title: 'sidenav.fideles.list',
            icon: ColorIcon(StarOutlined, communauteColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["communaute"],
        },
        {
            key: 'horaires-list',
            path: `${APP_PREFIX_PATH}/horaires/list`,
            title: 'sidenav.horaries.list',
            icon: ColorIcon(ClockCircleOutlined, communauteColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["communaute"],
        },
        {
            key: 'events-list',
            path: `${APP_PREFIX_PATH}/events/list`,
            title: 'sidenav.events.list',
            icon: ColorIcon(CalendarOutlined, communauteColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["COM", "FINANCE", "ESSENTIEL"],
            permission: ["communaute"],
        },
        {
            key: 'actu-list',
            path: `${APP_PREFIX_PATH}/actu/list`,
            title: 'sidenav.actu.list',
            icon: ColorIcon(ProfileOutlined, communauteColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["COM", "FINANCE", "ESSENTIEL"],
            permission: ["communaute"],
        },
        {
            key: 'galeries-list',
            path: `${APP_PREFIX_PATH}/galeries/list`,
            title: 'sidenav.galeries.list',
            icon: ColorIcon(PictureOutlined, communauteColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["COM", "FINANCE", "ESSENTIEL"],
            permission: ["communaute"],
        }
    ]
}]

const campagneNavTree = [{
    key: 'campagne',
    path: `${APP_PREFIX_PATH}/campagne/list`,
    title: 'sidenav.campagne',
    icon: RocketOutlined,
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["campagne"],
    forfaits: ["CAMPAGNE"],
    submenu: [
        {
            key: 'campagne-list',
            path: `${APP_PREFIX_PATH}/campagne/list`,
            title: 'sidenav.campagne.list',
            icon: AppstoreOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-stats',
            path: `${APP_PREFIX_PATH}/campagne/stats`,
            title: 'Statistiques',
            icon: MonitorOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-settings',
            path: `${APP_PREFIX_PATH}/campagne/settings`,
            title: 'sidenav.campagne.settings',
            icon: LayoutOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-sources',
            path: `${APP_PREFIX_PATH}/campagne/sources`,
            title: 'sidenav.campagne.source',
            icon: AimOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-teams',
            path: `${APP_PREFIX_PATH}/campagne/teams`,
            title: 'sidenav.campagne.teams',
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-products',
            path: `${APP_PREFIX_PATH}/campagne/products`,
            title: 'sidenav.campagne.products',
            icon: ContainerOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-counters',
            path: `${APP_PREFIX_PATH}/campagne/counters`,
            title: 'sidenav.campagne.counters',
            icon: FieldNumberOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-shortcut-paiements',
            path: `${APP_PREFIX_PATH}/campagne/paiements`,
            title: "Paiements",
            icon: ExportOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
        {
            key: 'campagne-shortcut-abonnements',
            path: `${APP_PREFIX_PATH}/campagne/abonnements`,
            title: "Abonnements",
            icon: ExportOutlined,
            breadcrumb: false,
            submenu: [],
            forfaits: ["CAMPAGNE"],
            permission: ["campagne"],
        },
    ]
}]

const financeNavTree = [{
    key: 'finance',
    path: `${APP_PREFIX_PATH}/promesses/list`,
    title: 'sidenav.finance',
    icon: ColorIcon(RiseOutlined, financeColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["finance"],
    forfaits: ["FINANCE", "PROMESSE"],
    submenu: [
        {
            key: 'promesses-list',
            path: `${APP_PREFIX_PATH}/promesses/list`,
            title: 'sidenav.promesses.list',
            icon: ColorIcon(HourglassOutlined, financeColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE", "PROMESSE"],
            permission: ["finance"],
        },
        {
            key: 'cagnottes-list',
            path: `${APP_PREFIX_PATH}/cagnottes/list`,
            title: 'sidenav.cagnottes.list',
            icon: ColorIcon(AppstoreOutlined, financeColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["finance"],
        },
    ]
}]

const ServiceNavTree = [{
    key: 'Services',
    path: `${APP_PREFIX_PATH}/services/list`,
    title: 'sidenav.services',
    icon: ColorIcon(ApartmentOutlined, serviceColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["services"],
    forfaits: ["FINANCE"],
    submenu: [
        {
            key: 'service-groupe',
            path: `${APP_PREFIX_PATH}/services/group`,
            title: 'sidenav.service_group.list',
            icon: ColorIcon(ClusterOutlined, serviceColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["services"],
        },
        {
            key: 'services-list',
            path: `${APP_PREFIX_PATH}/services/list`,
            title: 'sidenav.service.list',
            icon: ColorIcon(CloudOutlined, serviceColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["services"],
        },
        {
            key: 'abo-list',
            path: `${APP_PREFIX_PATH}/abonnements/list`,
            title: 'sidenav.abonnements.list',
            icon: ColorIcon(CloudSyncOutlined, serviceColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["services"],
        },
    ]
}]

const ComptabiliteNavTree = [{
    key: 'Comptabilité',
    path: `${APP_PREFIX_PATH}/paiements/list`,
    title: 'sidenav.comptabilite',
    icon: ColorIcon(SwapOutlined, comptaColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["comptabilite"],
    forfaits: [],
    submenu: [
        /*{
          key: 'paiements-valide',
          path: `${APP_PREFIX_PATH}/paiements/valide`,
          title: 'sidenav.paiements.valide',
          icon: ExclamationCircleOutlined,
          breadcrumb: false,
          submenu: [],
          forfaits:["FINANCE"],
        },*/
        {
            key: 'paiements-list',
            path: `${APP_PREFIX_PATH}/paiements/list`,
            title: 'sidenav.paiements.list',
            icon: ColorIcon(VerticalAlignBottomOutlined, comptaColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["comptabilite"],
        },
        {
            key: 'paiements-recurrent',
            path: `${APP_PREFIX_PATH}/paiements/recurrent`,
            title: 'sidenav.paiements.recurrent',
            icon: ColorIcon(ReloadOutlined, comptaColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["comptabilite"],
        },
        {
            key: 'paiements-auto',
            path: `${APP_PREFIX_PATH}/paiements/auto`,
            title: 'sidenav.paiements.auto',
            icon: ColorIcon(FileSyncOutlined, comptaColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["comptabilite"],
        },
        {
            key: 'declaration-cerfa',
            path: `${APP_PREFIX_PATH}/declaration/cerfas`,
            title: 'Déclaration Fiscal',
            icon: ColorIcon(AuditOutlined, comptaColor),
            breadcrumb: false,
            submenu: [],
            forfaits: [],
            permission: ["comptabilite"],
        },
    ]
}]


const GestionNavTree = [{
    key: 'Gestion',
    path: `${APP_PREFIX_PATH}/comptes/all`,
    title: 'Gestion',
    icon: ColorIcon(InboxOutlined, dashboardColor),
    breadcrumb: false,
    isGroupTitle: false,
    permission: ["gestion"],
    forfaits: ['FINANCE'],
    submenu: [
        {
            key: 'comptes.list',
            path: `${APP_PREFIX_PATH}/comptes/all`,
            title: 'sidenav.comptes',
            icon: ColorIcon(PieChartOutlined, dashboardColor),
            breadcrumb: false,
            isGroupTitle: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["gestion"],
        },
        {
            key: 'livrets.list',
            path: `${APP_PREFIX_PATH}/livrets`,
            title: 'Livrets',
            icon: ColorIcon(FolderOutlined, dashboardColor),
            breadcrumb: false,
            isGroupTitle: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["gestion"],
        },
        {
            key: 'charges-list',
            path: `${APP_PREFIX_PATH}/charges/list`,
            title: 'sidenav.charges.list',
            icon: ColorIcon(MinusOutlined, dashboardColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["gestion"],
        },
        {
            key: 'rentrer-list',
            path: `${APP_PREFIX_PATH}/rentree/list`,
            title: 'sidenav.rentrer.list',
            icon: ColorIcon(PlusOutlined, dashboardColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["gestion"],
        },
        {
            key: 'intercomptes-list',
            path: `${APP_PREFIX_PATH}/intercomptes/list`,
            title: 'sidenav.intercomptes.list',
            icon: ColorIcon(SwapOutlined, dashboardColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["gestion"],
        },
        {
            key: 'bank-point',
            path: `${APP_PREFIX_PATH}/bank/point`,
            title: 'sidenav.bank.point',
            icon: ColorIcon(MergeCellsOutlined, dashboardColor),
            breadcrumb: false,
            submenu: [],
            forfaits: ["FINANCE"],
            permission: ["gestion"],
        },
    ]
}]

const navigationConfig = [
    //...peNavTree,
    ...dashBoardNavTree,
    ...webNavTree,
    ...formNavTree,
    ...crmNavTree,
    ...communauteNavTree,
    ...campagneNavTree,
    ...financeNavTree,
    ...ServiceNavTree,
    ...ComptabiliteNavTree,
    ...GestionNavTree,
]

export default navigationConfig;


export function getUrlFromkey(key) {
    return getNavItemFromkey(key)?.path
}

export function getNavItemFromkey(key, nd = navigationConfig) {

    for (let n of nd) {
        if (n.key === key) {
            return {...n}
        }

        let child = null
        if (Array.isArray(n.submenu)) {
            child = getNavItemFromkey(key, n.submenu)
        }
        if (child) {
            return {...child}
        }
    }

    return null
}
